import request from '@/utils/request'

export function valueCardRecordList (query) {
  return request({
    url: '/shop/value_card_record/',
    method: 'get',
    params: query
  })
}
export function createValueCardRecord (parameter) {
  return request({
    url: '/shop/value_card_record/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function putValueCardRecord (parameter, id) {
  return request({
    url: '/shop/value_card_record/' + id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
export function deleteValueCardRecord (id) {
  return request({
    url: '/shop/value_card_record/' + id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function valueCardList (query) {
  return request({
    url: '/shop/value_card/',
    method: 'get',
    params: query
  })
}

export function createValueCard (parameter) {
  return request({
    url: '/shop/value_card/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function putValueCard (parameter, id) {
  return request({
    url: '/shop/value_card/' + id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function getValueCardPwd (id) {
  return request({
    url: '/shop/value_card/' + id + '/password/',
    method: 'get'
  })
}

export function deleteValueCard (id) {
  return request({
    url: '/shop/value_card/' + id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 导出
 */
export function valueCardExport (parameter) {
  return request({
    url: '/shop/value_card_export/',
    method: 'get',
    headers: {
      'Content-Type': 'application/octet-stream'
    },
    responseType: 'blob',
    params: parameter
  })
}

export function valueCardConsumeRecordList (query) {
  return request({
    url: '/shop/value_card_consume_record/',
    method: 'get',
    params: query
  })
}

export function valueCardBindGoods (parameter) {
  return request({
    url: '/shop/value_card_bind_goods/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function getValueCardBindGoods (query) {
  return request({
    url: '/shop/value_card_bind_goods/',
    method: 'get',
    params: query
  })
}

export function userBindValueCardList (query) {
  return request({
    url: '/shop/shop_user_value_card/',
    method: 'get',
    params: query
  })
}

export function userBindValueCard (parameter) {
  return request({
    url: '/shop/shop_user_value_card/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function userUnbindValueCard (parameter, id) {
  return request({
    url: '/shop/shop_user_value_card/' + id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function deleteValueCardBindGoods (id) {
  return request({
    url: '/shop/value_card_bind_goods/' + id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
